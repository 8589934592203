<template>
    <td class="px-4 py-5 whitespace-nowrap text-sm font-medium text-white">
        {{ team.name }}              
    </td>
    <td class="px-4 py-5 whitespace-nowrap text-sm font-medium text-white">
        {{ team.coach }}              
    </td>
    <td class="px-4 py-5 whitespace-nowrap text-sm font-medium text-white">
        {{ team.playeramount }}
    </td>
    <td class="px-4 py-5 whitespace-nowrap text-sm font-medium text-white">
        {{ team.analyses }}
    </td>
    <td align="right">
        <div class="flex justify-end pr-6">
            <svg @click="$emit('open', 'edit'); $emit('teamData', team)" class="h-5 w-5 mr-3 text-white transform transition-all duration-150 hover:scale-125 hover:text-gray-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            <svg @click="$emit('open', 'delete'); $emit('teamData', team)" class="h-5 w-5 text-white transform transition-all duration-150 hover:scale-125 hover:text-scorelitred cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
        </div>
    </td>     
</template>

<script>
export default {
    name: 'TeamRow',
    props: ['team'],
    emits: ["open", 'teamData'],
}
</script>