<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Alle teams
                </h2>
                <div class="flex justify-end">
                    <div @click="showModal('create')" class="my-auto text-white cursor-pointer flex group">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                        </svg>
                        <p class="mr-6 group-hover:text-gray-400 transition-all duration-150">
                            Team aanmaken
                        </p>
                    </div>
                    <SearchInput v-model:value="searchQuery" placeholder="Team zoeken..." theme="dark"/>
                </div>
            </div>

            <div class="flex flex-col">
                <div class="overflow-x-auto">
                    <div class="py-6 align-middle inline-block min-w-full mx-4 md:mx-0 sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th v-for="row in rows" :key="row.key" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(row.key)">
                                                <div>{{ row.text }}</div>
                                                <div v-if="currentSort === row.key">
                                                    <svg class="ml-1 w-4 h-4 transform duration-200" :class="[currentSortDir == 'asc' ? '-rotate-180': '']" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                    </svg>
                                                </div>
                                                <div v-else class="ml-1 w-4 h-4">
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-500">
                                    <template v-if="teams.length">
                                        <tr v-for="team in sortedTeams" :key="team.name" class="">
                                            <TeamRow :team="team" @open="showModal" @teamData="this.selectedTeam = team" />
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td :colspan="rows.length" class="px-4 py-5 whitespace-nowrap text-sm font-medium text-center text-white">
                                            Er zijn momenteel geen teams
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>

    <!-- Edit Team Modal-->
    <Modal v-if="modalVisible && selectedModal == 'edit'" @closed="closeModal">
        <template v-slot:header>    
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Team wijzigen
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <p class="pb-6 mt-2">
                Gegevens van <strong>{{ selectedTeam.name }}</strong> aanpassen
            </p>
            <TextInput label="Teamnaam" border="enable" :value="selectedTeam.name" />
            <SelectInput label="Hoofdcoach" border="enable" class="mt-5"/>
        </template> 
        <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Opslaan
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Delete Team Modal-->
    <Modal v-if="modalVisible && selectedModal == 'delete'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Team verwijderen
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <p class="pb-6 mt-2">
                Weet je zeker dat je het team <strong>{{ selectedTeam.name }}</strong> wilt verwijderen uit je club?
            </p>            
        </template>
        <template v-slot:footer>        
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Verwijderen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>
    </Modal>

    <!-- Create Team Modal-->
    <Modal v-if="modalVisible && selectedModal == 'create'" @closed="closeModal">
        <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Team aanmaken
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <TextInput label="Teamnaam" border="enable" placeholder="Teamnaam" class="mt-10"/>
            <SelectInput label="Hoofdcoach" border="enable" class="mt-5"/>
        </template>
        <template v-slot:footer> 
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Aanmaken
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>    
    </Modal>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import TeamRow from "@/components/rows/TeamRow";
import TextInput from "@/components/forms/TextInput";
import SearchInput from "@/components/forms/SearchInput";
import SelectInput from "@/components/forms/SelectInput";
import Modal from "@/components/modals/Modal";

export default {
    name: "Teams",
    components: {
        DashboardWrapper,
        TeamRow,
        TextInput,
        SearchInput,
        SelectInput,
        Modal,
    },

    data() {
        return {
            modalVisible: false,
            selectedModal: '',
            selectedTeam: '',
            searchQuery: '',

            currentSort: 'name',
            currentSortDir: 'asc',

            rows: [
                { key:'name', text:'Teamnaam' },
                { key:'coach', text:'Hoofdcoach' },
                { key:'playeramount', text:'Spelers' },
                { key:'analyses', text:'Analyses' },
            ],

            // Testdata
            teams: [
                { name: 'Mix U12',        coach: 'Albert de Jong',        playeramount: 10,     analyses: 3, },
                { name: 'Heren U14',      coach: 'Jan Koopmans',          playeramount: 11,     analyses: 0, },
                { name: 'Dames U16',      coach: 'Eline Pesman',          playeramount: 7,      analyses: 0, },
                { name: 'Heren U18',      coach: 'Frank Stege',           playeramount: 1,      analyses: 1, },
                { name: 'Dames U20',      coach: 'Joël Westen',           playeramount: 5,      analyses: 2, },
                { name: 'Heren U22',      coach: 'Sebastiaan Hartman',    playeramount: 1,      analyses: 5, },
            ],
        }
    },  

    methods: {
        sort:function(s) {
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
        },

        closeModal() {
            this.modalVisible = false;
        },

        showModal(type) {
            this.selectedModal = type;
            this.modalVisible = true;        
        },
    },

    computed: {
        sortedTeams:function(){
            return this.resultQuery.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0
            }); 
        },

        resultQuery(){
            if(this.searchQuery){
            return this.teams.filter(team=>{
            return this.searchQuery.toLowerCase().split(' ').every(v => team.name.toLowerCase().includes(v))
            })
            }
            else {
                return this.teams;
            }
        },
    }
}
</script>